import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import SocialShare from "../components/social-share-links"
import VejaMais from "../components/veja-mais"

import { MetaData } from "../components/meta"

const PostPage = ({ data, location }) => {
  const post = data.ghostPost
  return (
    <>
      <MetaData data={data} location={location} type="article" />
      <Helmet>
        <style type="text/css">{`${post.codeinjection_styles}`}</style>
      </Helmet>
      <Layout>
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="col-12">
              <h6 className="tag-title">
                {post.tags[1] ? post.tags[1].name : post.tags[0].name}
              </h6>
              <h2>{post.title}</h2>
              {post.custom_excerpt ? <h6>{post.custom_excerpt}</h6> : null}
            </div>

            <hr className="w-100" />
            <div className="col-12 d-flex justify-content-between">
              <p className="">
                <small>
                  Atualizado {post.updated_at_pretty}- por{" "}
                  {post.primary_author.name}
                </small>
              </p>
              <div className="">
                <SocialShare href={location.href} title={post.title} />
              </div>
            </div>

            {/* {post.feature_image ? (
            <div className="col-12">
              <figure>
                <img
                  src={post.feature_image}
                  alt={post.title}
                  className="img-fluid"
                />
                <figcaption>{post.title}</figcaption>
              </figure>
            </div>
          ) : null} */}

            <section
              className="col-12 load-external-scripts"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
            <div className="col-12">
              <div className="d-flex align-items-baseline justify-content-end">
                <h6>Compartilhe: |</h6>
                <SocialShare href={location.href} title={post.title} />
              </div>
            </div>

            <div className="row bg-light p-3 mt-3 rounded-lg">
              <div className="col-3"></div>
              <VejaMais />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PostPage

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
  }
`
