import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const VejaMais = ({ data }) => {
  const noticias = data.allGhostPost.edges

  return (
    <div>
      <h2>
        <strong>
          <small className="text-danger">
            <i>
              {/* <FontAwesomeIcon icon={["fas", "plus"]} /> */}
            </i>
          </small>{" "}
          notícias
        </strong>
      </h2>
      <div className="row mt-3">
        {noticias.map(({ node }) => (
          <div key={node.uuid} className="col-4">
            <Link to={`noticias/${node.slug}`}>
              <h6 className="tag-title">
                {node.tags[1] ? node.tags[1].name : node.tags[0].name}
              </h6>
              <div className="img-wrapper">
                <img
                  src={node.feature_image}
                  className="img-fluid"
                  alt={node.title}
                />
              </div>
              <p className="mt-2">
                {/* {node.title.length >= 55 ? node.title.substr(0, 50) + ' ...' : node.title} */}
                <strong>{node.title}</strong>
              </p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query VejaMaisQuery {
        allGhostPost(
          sort: { order: DESC, fields: [published_at] }
          limit: 3
          skip: 0
          filter: {
            slug: { ne: "data-schema" }
            feature_image: { ne: null }
            tags: { elemMatch: { slug: { eq: "noticias" } } }
          }
        ) {
          edges {
            node {
              ...GhostPostFields
            }
          }
        }
      }
    `}
    render={data => <VejaMais data={data} {...props} />}
  />
)
